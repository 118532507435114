<template>
    <div>
        <Layout>
            <div class="row form__common--center">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title--screen">LP検索</h3>
                        </div>
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-sm-6">
                                    <form class="needs-validation" autocomplete="off">
                                        <div v-if="checkShowFilter" class="row form-group">
                                            <div class="col-sm-12">
                                                <KeyWordTag :config="configKeyword" class="" />
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.nameCampaign') }}</label>
                                                <div class="col-sm-9">
                                                    <Multiselect
                                                        :id="`select-campaign-default`"
                                                        :value.sync="formSearch.campaign_name"
                                                        :options="listDataCampaign"
                                                        :config="configForm.campaign"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.nameLandingPage') }}</label>
                                                <div class="col-sm-9">
                                                    <Multiselect
                                                        :id="`select-campaign-default`"
                                                        :value.sync="formSearch.name"
                                                        :options="listDataNameLandingPage"
                                                        :config="configForm.nameLandingPage"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{
                                                    $t('landingPage.list.businessAssistant')
                                                }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :model.sync="formSearch.sale_staff"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.startDateCP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.campaign_start_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.endDateCP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.campaign_end_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.startDateLP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.lp_start_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.endDateLP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.lp_end_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center mt-4 position-relative">
                                            <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                            <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>
                                            <a @click="handleShowFilter()" class="filter" href="javascript:void(0)">
                                                <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title--screen">
                                キャンペーン検索結果 <span>件数（{{ configPage.total }})</span>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-center input-group-sm" style="white-space: nowrap">
                                        検索条件：
                                        <input type="text" class="form-control ml-3" v-model="bookmarkName" style="width: 200px" />
                                        <button class="btn btn-sm btn-primary ml-2" @click="addBookMark()">{{ $t('btn.save') }}</button>
                                    </div>
                                </div>
                            </div>

                            <TableListComponent
                                :config="configTable"
                                :dataList="listDataTable"
                                :isLoading="isLoading"
                                :configPage.sync="configPage"
                                :stickyAction="true"
                                @onChangePage="getList()"
                            >
                                <template #row="{ row, index }">
                                    <tr @dblclick="handleGoTo('/landing-page/view', row)">
                                        <td>
                                            {{ index }}
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ row?.campaign?.name ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ row?.name ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small" v-b-tooltip.hover :title="row?.url_public ?? ''">
                                                {{ row?.url_public ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ handleStatus(row?.status) ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-medium">
                                                {{ formatDateCommon(row?.lp_start_date, 'dateTimeJP') ?? '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ formatDateCommon(row?.lp_end_date, 'dateTimeJP') ?? '' }}
                                            </div>
                                        </td>
                                        <td class="sticky_action">
                                            <div class="row-data-small">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-info action__record"
                                                    @click="handleGoTo('/landing-page/view', row)"
                                                    >{{ $t('btn.detail') }}</a
                                                >
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-success action__record"
                                                    @click="handleGoTo('/landing-page/edit', row)"
                                                    >{{ $t('btn.edit') }}</a
                                                >
                                                <a href="javascript:void(0)" @click="handleCopy('/documents', row)" class="text-primary">{{
                                                    $t('btn.copy')
                                                }}</a>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </TableListComponent>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        <Footer />
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import TableListComponent from '@/components/TableList/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';

import { configs, STATUS_FORM, initFormSearch, STATUS_SETTING_BASIC } from './constants';
import { InputText } from '@/components/Input';
import { masterMethods, landingPageMethods } from '@/state/helpers';
import { formatDateCommon } from '@/utils/format';

export default {
    name: 'LandingPage',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        Multiselect,
        DateNormal,
        TableListComponent,
        InputText,
        KeyWordTag
    },
    data() {
        return {
            configForm: configs,
            configTable: configs.configTable,
            configPage: configs.configPage,
            statusForm: STATUS_FORM,
            formSearch: initFormSearch(),
            listDataTable: [],
            listDataCampaign: [],
            listDataNameLandingPage: [],
            configKeyword: {
                arrayTag: [],
                placeholder: this.$t('landingPage.list.placeholderSearch'),
                isShowLable: true
            },
            bookmarkName: '',
            isLoading: false,
            checkShowFilter: true
        };
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        async handleGetMasterCampaign() {
            try {
                const dataRes = await this.listMaster('all/campaigns');
                this.listDataCampaign = dataRes ?? [];
            } catch (error) {
                console.log('error', error);
            }
        },

        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            let copyForm = Object.assign({}, this.formSearch);
            if (this.checkShowFilter) {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            } else {
                if (copyForm) {
                    if (copyForm.campaign_name !== null) copyForm.campaign_name = copyForm.campaign_name?.id;
                    for (const [key, value] of Object.entries(copyForm)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            }
            return query.join('&');
        },

        async getList() {
            let query = this.getQueryString();
            this.isLoading = true;
            try {
                const data = await this.getListLandingPage(query);
                this.listDataTable = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listDataTable.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                console.log('error', error);
            }
        },

        addBookMark() {},
        clear() {
            this.configKeyword.arrayTag = [];
            this.formSearch = initFormSearch();
            this.getList();
        },
        search() {
            this.getList();
        },

        handleGoTo(url, row) {
            this.$router.push({ path: url, query: { id: row.id } });
        },
        handleCopy() {},
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            this.configKeyword.arrayTag = [];
            this.formSearch = initFormSearch();
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        handleStatus(status) {
            if (!status) return '';
            return STATUS_SETTING_BASIC.find((s) => s.id == status)?.value ?? '';
        }
    },
    mounted() {
        this.getList();
        this.handleGetMasterCampaign();
    }
};
</script>

<style lang="scss" scoped></style>
